@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: var(--zombie);
  --secondary: var(--cod-gray1);

  --main-text-color: var(--white1);
  --list-title-color: var(--zombie);
  --list-company-color: var(--white1);
  --list-loc-color: var(--white2);
  --visited-color: var(--husk);

  /* profile */
  --custom-weight: 700;
  --main-border-color: hsla(0, 0%, 100%, 0.24);
  --page-text-color: var(--white1);
  --page-text-color2:  var(--white5);
  --main-border-radius: 8px;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
}

body {
  font-family: 'Tahoma', Verdana, Geneva, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  min-height: 100vh;
  color: #fff;
  background: linear-gradient(180deg, #686768, #000000);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.container {
  max-width: 940px !important;
  margin: auto;
}
.hidden {
  display: none;
}

.header {
  background: url('/mc/header-background.jpg') repeat-y top center;
}

.search-link {
  text-shadow: 0 4px 4px #000000;
}

.search-link:hover {
  color: #fff;
  text-decoration: underline;
  text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.4);
}

.main {
  box-shadow: 0px 0px 16px 0px #0000001f;
  background-color: var(--tundora);
}
.arrow {
  display: block;
  width: 8px;
  height: 8px;
  border-top-width: 2px;
  border-right-width: 2px;
  border-left-width: 0px;
  border-bottom-width: 0px;
  border-style: solid;
  border-color: var(--secondary);
  transform: rotate(45deg);
  transition: all 0.3s ease-in-out;
}

.title-shadow-mc {
  text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.4);
}
.custom-shadow-mc {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
}

.popup-bg-blur-mc {
  backdrop-filter: blur(5.699999809265137px);
  background: rgba(0, 0, 0, 0.32);
}

